@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

.App {
  box-sizing: border-box; }

.App {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  /*font-size: 1.6rem;*/
  line-height: 1.7;
  color: #777777;
  padding: 1rem; }

.heading-primary {
  color: #ffffff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem; }
  .heading-primary--main {
    display: block;
    font-size: 4rem;
    font-weight: 400;
    letter-spacing: 2.5rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out; }
  .heading-primary--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out; }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #55c57a, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .3s; }

.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase; }

.paragraph {
  font-size: 1.6rem; }
  .paragraph:not(:last-child) {
    margin-bottom: 3rem; }

.u-center-text {
  text-align: center; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem; }

.u-margin-bottom-medium {
  margin-bottom: 4rem; }

.u-margin-bottom-big {
  margin-bottom: 8rem; }

.btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1.6rem; }

.btn:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right bottom, rgba(79, 77, 76, 0.5), rgba(47, 44, 43, 0.5));
  color: white;
  font-weight: bold; }
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn--white {
  background-color: #ffffff;
  color: #777777; }
  .btn--white::after {
    background-color: #ffffff; }

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

.btn--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #7ed56f;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #7ed56f;
  padding: 3px;
  transition: all .2s;
  border-radius: 10rem;
  width: 15rem;
  text-align: center; }

.btn-text:hover {
  background-color: #7ed56f;
  color: #ffffff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 50rem; }
  .card__side {
    color: #ffffff;
    font-size: 2rem;
    height: 50rem;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
    .card__side--front {
      background-color: #ffffff; }
    .card__side--back {
      transform: rotateY(180deg); }
      .card__side--back-1 {
        background-image: linear-gradient(to right bottom, #ffb900, #ff7730); }
  .card:hover .card__side--front {
    transform: rotateY(180deg); }
  .card:hover .card__side--back {
    transform: rotateY(0); }

.composition {
  position: relative; }
  .composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    transition: all .2s;
    outline-offset: 1rem; }
    .composition__photo--p1 {
      left: 0;
      top: -2rem; }
    .composition__photo--p2 {
      right: 0;
      top: 2rem; }
    .composition__photo--p3 {
      left: 20%;
      top: 10rem; }
    .composition__photo:hover {
      outline: 1.5rem solid #7ed56f;
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s; }
  .feature-box__icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #55c57a, #28b485);
    -webkit-background-clip: text;
    color: transparent; }
  .feature-box:hover {
    transform: translateY(-1.5rem) scale(1.03); }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .row .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4); }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem); }

.header {
  height: 95vh;
  background-image: linear-gradient(to right bottom, rgba(85, 197, 122, 0.5), rgba(40, 180, 133, 0.5)), url("/src/img/first_view.jpg");
  background-size: cover;
  background-position: top;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%); }
  .header__logo-box {
    position: absolute;
    top: 4rem;
    left: 4rem; }
  .header__logo {
    height: 8rem; }
  .header__text-box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }

.section-about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -20vh; }

.section-features {
  padding: 20rem 0;
  background-image: linear-gradient(to right bottom, rgba(85, 197, 122, 0.5), rgba(40, 180, 133, 0.5)), url("/src/img/img4.jpeg");
  background-size: cover;
  transform: skewY(-7deg);
  margin-top: -12rem; }
  .section-features > * {
    transform: skewY(7deg); }

.popular-products {
  background-color: #f7f7f7;
  padding: 25rem 0 50rem 0;
  margin-top: -10rem; }
